<template>
  <div class="content">
    <TopBar title="订单详情" :leftIcon="'arrow_back'" :bottom-margin="55"/>
    <div class="card-content">
      <div class="inner-content">
        <strong class="order-status">{{ order.orderStatusDesc }}</strong>
        <div style="font-weight: normal" class="order-info text-subtitle-1">
          <span>订单编号：<br><span>{{ order.orderNumber }}</span></span>
          <span>技师：{{ order.workerName }}</span>
          <span>酒店/公寓：{{ order.hotel }}</span>
          <span>房间/门牌号：{{ order.room }}</span>
          <span>电话：{{ order.phone }}</span>
          <span>预约时间：{{ dayjs(order.created).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>下单时间：{{ dayjs(order.serviceReservationTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </div>
        <br>
        <v-btn x-large rounded color="error">去支付</v-btn>
      </div>
    </div>
    <div class="card-content">
      <strong class="text-h6">{{ order.name }}</strong>
      <div class="text-subtitle-1" style="display: flex;margin-top: 10px" v-for="(item, index) in order.itemList"
           :key="index">
        <img width="110" height="110" :src="item.picUrl" alt="">
        <div style="width: 100%;margin-left: 15px">
          <span>{{ item.name }}</span>
          <br>
          <div style="float: right;text-align: right">
            <span><v-icon>schedule</v-icon>
            {{ item.serviceTime }}分钟</span><br>
            <span style="color: red">{{ item.currency }}{{ item.price }}</span><br>
            <span>x{{ item.quantity }}</span>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 5px">
        <span>
          <v-icon>local_shipping</v-icon> &nbsp; 配送方式
        </span>
        <span>{{ order.delivery }}</span>
      </div>
    </div>

    <div class="card-content">
      <div class="pay-card text-subtitle-1" style="">
        <div>
          <span>支付方式</span>
          <span>
            <img width="20" :src="payTypeInfo.imgUrl" alt="">
            {{ payTypeInfo.text }}
          </span>
        </div>

        <div>
          <span>项目应付金额</span>
          <span>{{ order.currency }} {{ order.payAmount }}</span>
        </div>
        <div>
          <span>已支付定金</span>
          <span>-{{ order.currency }} {{ order.reserveAmount }}</span>
        </div>
        <div>
          <span>定金说明</span>
          <span style="max-width: 50%">{{ order.reserveAmountDesc }}</span>
        </div>
        <div>
          <span>应付现金合计</span>
          <span>{{ order.currency }} {{ order.cashTotal }}</span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import {getOrderDetails} from "@/api/worker";
import dayjs from "dayjs";
import {getPayTypeInfo} from "@/utils/util";

export default {
  name: "OrderDetail",
  components: {TopBar},
  data() {
    return {
      orderId: null,
      order: null,
      payTypeInfo: null
    };
  },
  mounted() {
    this.getOrderDetails();
    window.scroll(0, 0)
  },
  created() {
    this.orderId = this.$route.params.orderId;
  },
  methods: {
    dayjs,
    getOrderDetails() {
      getOrderDetails(this.orderId).then(res => {
        this.order = res.data.result;
        console.log('getOrderDetails: ', this.order);
        this.payTypeInfo = getPayTypeInfo(this.order.payType);
      });
    }
  }
};
</script>

<style scoped>
.content {
  background-color: #ececec;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.card-content {
  padding: 20px 10px;
  background-color: white;
}

.inner-content {
  margin: 0 auto;
  text-align: center;
}

.order-status {
  font-size: 22px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

.order-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left; /* 使描述部分左对齐 */
  margin: 0 auto; /* 整体居中 */
  max-width: 220px; /* 控制描述部分的最大宽度 */
}

.order-info span {
  line-height: 1.5;
}

.pay-card div {
  display: flex;
  justify-content: space-between;
}

.pay-card div:nth-of-type(1) span:nth-of-type(2),
.pay-card div:nth-of-type(2) span:nth-of-type(2),
.pay-card div:nth-of-type(3) span:nth-of-type(2),
.pay-card div:nth-of-type(4) span:nth-of-type(2),
.pay-card div:nth-of-type(5) span:nth-of-type(2) {
  font-weight: bold;
}

</style>
