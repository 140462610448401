<template>
  <div class="ContinuPlay_box" @touchstart="TouchStart" @touchmove="TouchMove" @touchend="TouchEnd">
    <div class="items_box" :style="{ transform: `translateX(-${CurrentImg * 100}%)`, transition: 'transform 0.3s ease' }">
      <div v-for="(item, index) in banners" class="slide" :key="index">
        <img :src="item" alt="">
      </div>
    </div>
    <div class="points_box">
      <div class="points">
        <div v-for="(item, index) in banners" :key="index" class="each_point" :class="{ current: index === CurrentImg }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageSlider",
  props: ['banners'],
  data() {
    return {
      StartPoint: 0,
      EndPoint: 0,
      MoveLength: 0,
      CurrentImg: 0,
    }
  },
  methods: {
    TouchStart(event) {
      this.StartPoint = event.changedTouches[0].pageX;
    },
    TouchMove(event) {
      this.EndPoint = event.changedTouches[0].pageX;
      this.MoveLength = this.StartPoint - this.EndPoint;
    },
    TouchEnd() {
      if (Math.abs(this.MoveLength) > 0.2 * window.innerWidth) {
        if (this.MoveLength > 0 && this.CurrentImg < this.banners.length - 1) {
          this.CurrentImg++;
        } else if (this.MoveLength < 0 && this.CurrentImg > 0) {
          this.CurrentImg--;
        }
      }
      this.MoveLength = 0; // Reset MoveLength after touch end
    }
  }
}
</script>

<style scoped>
.ContinuPlay_box {
  overflow: hidden;
  position: relative;
  width: 100%;
  touch-action: pan-y;
}

.ContinuPlay_box .items_box {
  display: flex;
  width: 100%;
}

.ContinuPlay_box .slide {
  flex-shrink: 0;
  width: 100%;
}

.ContinuPlay_box .slide img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.points_box {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  width: 100%;
}

.points {
  display: flex;
  justify-content: space-evenly;
  width: 33%;
}

.points .each_point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.7;
}

.points .current {
  background: #ff0031;
}
</style>
