export const urlEncode = obj => {
    let params = Object.keys(obj).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    }).join('&')
    return params
}

export function getPayTypeInfo(payType) {
    const payTypeList = [
        {type: 1, text: 'AliPay', imgUrl: require('@/assets/pay/orderImg02.png')},
        {type: 2, text: 'Weixin Pay', imgUrl: require('@/assets/pay/orderImg03.png')},
        {type: 3, text: 'Weixin Pay', imgUrl: require('@/assets/pay/orderImg03.png')},
        {type: 8, text: 'PayPal', imgUrl: require('@/assets/pay/PayPal.png')},
        {type: 9, text: 'PayPal', imgUrl: require('@/assets/pay/PayPal.png')},
        {type: 10, text: 'Thailand Pay', imgUrl: require('@/assets/pay/thailand.png')},
        {type: 55, text: 'Other', imgUrl: require('@/assets/pay/dollar.png')},
    ];
    const payTypeInfo = payTypeList.find(item => item.type === payType);

    return payTypeInfo || {text: 'Unknown', imgUrl: require('@/assets/pay/dollar.png')};
}
