<template>
  <v-dialog
      v-model="dialogVisible"
      max-width="600px"
      @input="onDialogClose"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        {{ content }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="handleConfirm"
        >
          Confirm
        </v-btn>
        <v-btn
            color="red darken-1"
            text
            @click="handleClose"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    value: { // 用于 v-model 双向绑定
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.value
    };
  },
  watch: {
    value(newVal) {
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
      this.dialogVisible = false;
    },
    handleClose() {
      this.$emit('close');
      this.dialogVisible = false;
    },
    onDialogClose() {
      // This method is triggered when the dialog is closed via clicking outside
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
/* 可以添加自定义样式 */
</style>
