<template>
  <v-app>
    <v-content>
      <div id="app">
        <!--    <img alt="Vue logo" src="./assets/enjoyinstantlylogo.png">-->
        <!--        <router-view></router-view>-->
        <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </v-content>
  </v-app>

</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0px 0px 10px 0px;
}


</style>
