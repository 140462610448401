<template>
  <v-dialog
      style="z-index: 1000"
      v-model="internalDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card >
      <v-toolbar dark color="#e34146">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>选择地址</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn class="text-h6" dark text @click="confirmNavigationNew">
            确认位置
          </v-btn>
        </v-toolbar-items>
        <br>
      </v-toolbar>
      <v-text-field
          style="margin-top: 5px"
          placeholder="Enter a location"
          prepend-inner-icon="mdi-map-marker"
          id="searchInput"
          hide-details
          :value="this.infoContent"
          @input="searchPlaces"
          filled
      ></v-text-field>
      <v-divider/>
      <GmapMap
          :center="mapCenter"
          :zoom="10"
          style="width: 100%; height: 500px;"
      >
        <GmapMarker
            v-for="marker in markers"
            :key="marker.place_id"
            :position="marker.geometry.location"
            :clickable="true"
            :draggable="false"
        />
      </GmapMap>
<!--      <input-->
<!--          type="text"-->
<!--          id="searchInput"-->
<!--          placeholder="Enter a location"-->
<!--          :value="this.infoContent"-->
<!--          @input="searchPlaces"-->
<!--      />-->

      <p v-if="currentPlace">Selected Place: {{ currentPlace.name }}</p>
      <p v-if="currentPlace">Address: {{ currentPlace.formatted_address }}</p>
      <p v-if="currentPlace">Latitude: {{ currentPlace.geometry.location.lat() }}</p>
      <p v-if="currentPlace">Longitude: {{ currentPlace.geometry.location.lng() }}</p>
<!--      <button @click="confirmNavigation()">确认位置</button>-->
    </v-card>
  </v-dialog>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    console.log('lat:')
    console.log(this.$route.params.lat)
    console.log('lng:')
    console.log(this.$route.params.lng)
    let lat = 13.6858795;
    let lng = 100.3938239;
    if (this.$route.params.lat) {
      lat = this.$route.params.lat;
    }
    if (this.$route.params.lng) {
      lat = this.$route.params.lng;
    }
    return {
      mapCenter: {lat: lat, lng: lng},
      markers: [],
      autocomplete: null,
      infoContent: '',
      currentPlace: null,
    };
  },
  computed: {
    google: gmapApi,
    internalDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    }
  },
  created() {
  },
  // mounted() {
  //   this.initAutocomplete();
  // },
  watch: {
    google(api) {
      if (api) {
        this.initAutocomplete();
      }
    },
    // internalDialog(newVal) {
    //   if (newVal) {
    //     // 渲染完成后再调用
    //     this.$nextTick(() => {
    //       this.initAutocomplete();
    //     });
    //   }
    // },
  },
  methods: {
    initAutocomplete() {
      console.log('initAutocomplete')
      const input = document.getElementById('searchInput');
      this.autocomplete = new this.google.maps.places.Autocomplete(input);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);

    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        this.mapCenter = place.geometry.location;
        this.markers = [place];
        this.currentPlace = place;
        console.log('Selected place: ', this.currentPlace);
      } else {
        console.log('No details available for input: ' + place.name);
      }
    },
    searchPlaces(event) {
      if (event.target.value === '') {
        this.markers = [];
      }
    },
    // confirmNavigation() {
    //   console.log('confirmNavigation')
    //   history.replaceState(null, '', '/');
    //   this.$router.push({
    //     name: 'BookingPage', params: {
    //       latitude: this.currentPlace.geometry.location.lat(), longitude: this.currentPlace.geometry.location.lng(),
    //       formatted_address: this.currentPlace.formatted_address, hotel: this.currentPlace.name
    //     }
    //   });
    // },
    closeDialog() {
      this.$emit('closeDialog')
      console.log("closeDialog")
    },
    confirmNavigationNew() {
      this.$emit('locationSelected', {
        latitude: this.currentPlace.geometry.location.lat(),
        longitude: this.currentPlace.geometry.location.lng(),
        address: this.currentPlace.formatted_address,
        name: this.currentPlace.name
      });
      this.$emit('closeDialog');
      console.log("confirmNavigationNew")
    },
  },
};
</script>

<style>
#searchInput {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
  z-index: 1001;
}
.pac-container.pac-logo.hdpi {
  z-index: 1002;
}

</style>
