<template>
  <v-navigation-drawer
      :value="value"
      @input="updateValue"
      fixed
      right
      temporary
      width="280"
      style="z-index: 2002"
      @close="handleClose"
  >
    <!--    <v-card>-->
    <!--      <v-card-title class="red&#45;&#45;text text-h5">筛选条件</v-card-title>-->
    <v-card-text>
      <!-- 工作状态 -->
      <v-card-subtitle class="text-h6 padding">工作状态</v-card-subtitle>
      <v-chip-group v-model="selectedStatus" column mandatory>
        <v-chip
            v-for="status in statusOptions"
            :key="status.value"
            :value="status.value"
            :color="selectedStatus === status.value ? 'red' : 'grey lighten-1'"
            text-color="white"
        >
          {{ status.label }}
        </v-chip>
      </v-chip-group>

      <!-- 技师性别 -->
      <v-card-subtitle class="text-h6 mt-4 padding">技师性别</v-card-subtitle>
      <v-chip-group v-model="selectedGender" column mandatory>
        <v-chip
            v-for="gender in genderOptions"
            :key="gender.value"
            :value="gender.value"
            :color="selectedGender === gender.value ? 'red' : 'grey lighten-1'"
            text-color="white"
        >
          {{ gender.label }}
        </v-chip>
      </v-chip-group>

      <v-card-subtitle class="text-h6 mt-4 padding">分类</v-card-subtitle>
      <v-row>

        <v-col cols="6">
          <v-select
              style="margin-top: 5px"
              :items="merchantList"
              label="分类"
              v-model="selectedMerchantId"
              filled dense
              item-text="name"
              item-value="merchantId"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
              style="margin-top: 5px"
              :items="languageList"
              label="语言"
              v-model="selectedLanguage"
              filled
              dense
              item-text="name"
              item-value="code"
          ></v-select>
        </v-col>
      </v-row>
      <!-- 技师年龄 -->
      <v-card-subtitle class="text-h6 mt-4 padding">技师年龄</v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="startAge"
              label="最小年龄"
              hide-details="auto"
              type="number"
              outlined
              dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
              v-model="endAge"
              label="最大年龄"
              hide-details="auto"
              type="number"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-subtitle class="text-h6 mt-4 padding">技师身高</v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="startHt"
              label="最小身高"
              hide-details="auto"
              type="number"
              outlined
              dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
              v-model="endHt"
              label="最大身高"
              hide-details="auto"
              type="number"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 技师姓名 -->
      <v-card-subtitle class="text-h6 mt-4 padding">技师姓名</v-card-subtitle>
      <v-text-field
          v-model="technicianName"
          label="请输入技师姓名"
          hide-details
          outlined
          dense
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="resetFilters" class="red--text">重置</v-btn>
      <v-btn color="red" dark @click="applyFilters">确定</v-btn>
    </v-card-actions>
    <!--    </v-card>-->
  </v-navigation-drawer>
</template>

<script>
import {getMerchant} from "@/api/worker";

export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    drawerShow(val) {
      // if (!val){
      //   this.$emit('closed')
      // }
      console.log('drawerShow: ', val)
    }
  },
  data() {
    return {
      selectedStatus: '',
      selectedGender: '',
      startAge: '',
      endAge: '',
      startHt: '',
      endHt: '',
      technicianName: '',
      statusOptions: [
        {value: '', label: "全部"},
        {value: 1, label: "可预约"},
        {value: 3, label: "忙中"},
        {value: 2, label: "休息中"}
      ],
      genderOptions: [
        {value: '', label: "全部"},
        {value: 1, label: "男技师"},
        {value: 2, label: "女技师"}
      ],
      merchantList: [],
      selectedMerchantId: -1,
      selectedLanguage: '',
      languageList: [
        {name: '全部', code: ''},
        {name: '中文', code: 'zh'},
        {name: 'English', code: 'en'},
        {name: 'ไทย', code: 'th'}
      ],
    };
  },
  computed: {
    drawerShow: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    this.getMerchant()
  },
  methods: {
    resetFilters() {
      this.selectedStatus = '';
      this.selectedGender = '';
      this.startAge = '';
      this.endAge = '';
      this.technicianName = '';
    },
    applyFilters() {
      const filters = {
        workStatus: this.selectedStatus,
        gender: this.selectedGender,
        startAge: this.startAge,
        endAge: this.endAge,
        startHt: this.startHt,
        endHt: this.endHt,
        workerName: this.technicianName,
        languageCode: this.selectedLanguage,
        selectedMerchantId: this.selectedMerchantId
      };
      console.log('filters: ', filters)
      this.$emit('apply-filters', filters);
      this.drawerShow = false;
    },
    getMerchant() {
      getMerchant({proxyId: process.env.VUE_APP_proxyId}).then((res => {
        if (res.data.code === 0 || res.data.code === 200) {
          let merList = [];
          merList.push({
            "merchantId": -1,
            "name": "全部"
          })
          // 假设 res.data.result 是一个数组
          if (Array.isArray(res.data.result)) {
            merList.push(...res.data.result);
          } else {
            // 如果 result 不是数组，直接添加
            merList.push(res.data.result);
          }
          this.merchantList = merList;
          console.log('merchantList: ', this.merchantList)
        }
      }));
    },
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
    handleClose() {
      this.$emit('input', false);
    },
  },
};
</script>
<style scoped>
.padding {
  padding: 5px;
}
</style>
