<template>
  <div>
    <v-overlay :value="internalLoading" opacity="0.2"></v-overlay>
    <v-dialog v-model="internalLoading" hide-overlay persistent height="200" width="300">
      <v-card color="orange darken-4" dark>
        <v-card-text style="font-size: 20px">
          加载中...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalLoading: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>
/* 在这里添加你的样式 */
</style>
