<template>
  <v-dialog
      style="z-index: 1000"
      v-model="internalDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >

    <div>
      <v-snackbar
          v-model="snackbar"
          :multi-line="true"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-card style="background-color: #eaeaea">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="this.closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>选择服务时间（当地时间）</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs flat v-model="tab" style="transform: translateX(-26px)">
            <v-tab v-for="item in dateArray" :key="item.date">
              <div @click="handleDateClick(item)" class="text-subtitle-2"
                   style="display: flex;flex-direction: column;">
                <div>{{ item.todayOfWeek }}</div>
                <div>{{ formatDate(item.date) }}</div>
              </div>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div class="select-time">
        <v-chip class="item-time"
                @click="selectTime(item)"
                :style="getChipStyle(item)"
                label v-for="item in timeArray" :key="item.time">
          <div class="text-subtitle-1">
            {{ parseTime(item.time, "{h}:{i}") }}
          </div>
          <div v-if="!item.isOptional" class="status-isOptional">约满</div>
          <div v-if="item.isNight" class="status-isNight">夜间</div>
        </v-chip>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {getWorkerTimeSlot} from "@/api/worker";

export default {
  name: "SelectServiceTime",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    workerId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      serviceTimes: [],
      dateArray: [],
      week: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      optionalTime: [],
      timeArray: [],
      count: 0,
      time: new Date().getTime(),
      snackbar: false,
      text: '',
      tab: null
    }
  },

  computed: {
    internalDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    }
  },
  watch: {
    internalDialog(newVal) {
      if (newVal) {
        this.init();
      }
    }
  },
  methods: {
    init() {
      this.serviceTimes = []
      this.dateArray = []
      this.optionalTime = []
      this.timeArray = []
      this.tab = null

      this.getCurrent();//定位
      this.getWorkerTimeSlot();//获取工人可工作时间段
      // this.getDate();//日期
    },
    getCurrent() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log("lat: ", this.lat, "lng: ", this.lng)
            },
            error => {
              console.error("获取位置失败:", error);
              alert("获取位置失败 请手动选择")
            }
        );
      }
    },

    getDate() {
      let dateArray = [];
      let date = new Date();
      date.setHours(0, 0, 0, 0);

      for (let i = 0; i < 7; i++) {
        let day = date.getDay();
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date.getTime() === today.getTime()) {
          day = "today";
          // day = "今天";
        } else if (date.getTime() === (today.getTime() + 24 * 60 * 60 * 1000)) {
          day = "tomorrow";
          // day = "明天";
        } else {
          day = this.week[day];
        }

        dateArray.push({
          date: date.getTime(),
          todayOfWeek: day
        });

        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      }

      this.dateArray = dateArray;
      console.log("dateArray: ", this.dateArray);
      this.getTime(new Date());

    },
    getTime(selectDate) {
      console.log("selectDate: ", selectDate)
      let timeArray = [];
      let date = new Date();

      // 设置最后可预约时间为当天的23:30
      let lastDate = new Date();
      lastDate.setHours(23, 30, 0, 0);

      // 如果当前时间后的一个小时已超过最后可预约时间，则直接返回
      if ((date.getTime() + 60 * 60 * 1000) > lastDate.getTime()) {
        this.timeArray = timeArray;
        return;
      }

      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (new Date(selectDate).getTime() === today.getTime()) {
        console.log("Today");
        if (date.getHours() <= 7) {
          date.setHours(0, 0, 0, 0);
        }
        if (date.getMinutes() > 30) {
          date.setHours(date.getHours(), 0, 0, 0);
          date.setTime(date.getTime() + 60 * 60 * 1000);
        } else {
          date.setMinutes(30, 0, 0);
        }

        // 计算循环次数
        let loopTimes = (lastDate.getHours() - date.getHours()) * 2 + 2;
        for (let i = 0; i < loopTimes; i++) {
          let time = date.getTime() + i * 30 * 60 * 1000;
          timeArray.push({
            time: time,
            isOptional: this.isAvailable(time, this.optionalTime),
            isNight: this.isNight(time)
          });
        }
      } else {
        console.log("not Today");
        date = new Date(selectDate);
        date.setHours(0, 0, 0, 0);

        // 计算循环次数
        let loopTimes = (lastDate.getHours() - date.getHours()) * 2 + 2;
        for (let i = 0; i < loopTimes; i++) {
          let time = date.getTime() + i * 30 * 60 * 1000;
          timeArray.push({
            time: time,
            isOptional: this.isAvailable(time, this.optionalTime),
            isNight: this.isNight(time)
          });
        }
      }

      this.timeArray = timeArray;
      console.log("timeArray: ", this.timeArray);
      this.count = timeArray.length;

    },

    getWorkerTimeSlot() {
      getWorkerTimeSlot(this.workerId).then(res => {
        // console.log("getWorkerTimeSlot: ", res)
        if ((res.data.code === 0 || res.data.code === 200) && res.data.result.length > 0) {
          this.optionalTime = res.data.result
          console.log("optionalTime: ", this.optionalTime)
          this.getDate();//日期
        }
      })
    },

    isAvailable(time) {
      // console.log("isAvailable.optionalTime: ", this.optionalTime);
      for (let i = 0; i < this.optionalTime.length; i++) {
        if (!this.optionalTime[i].isExpire) {
          if (time >= this.optionalTime[i].startTime && time <= this.optionalTime[i].endTime) {
            return true;
          }
        }
      }
      return false;
    },
    isNight(time) {
      // 实现你的逻辑来判断时间是否为夜间
      let hour = new Date(time).getHours();
      return hour >= 19; // 假设晚上8点以后为夜间
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const day = date.getDate();
      const month = date.getMonth() + 1; // 月从0开始
      return `${month}/${day}`;
    },

    parseTime(time, cFormat) {
      if (!time || arguments.length === 0) {
        return null
      }

      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000
        if (typeof (time) === 'string') time = time.replace(/-/g, '/')
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') return ['日', '一', '二', '三', '四', '五', '六'][value]
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
    },

    getChipStyle(item) {
      // 根据不同条件动态生成样式对象
      let style = {};
      if (item.time < this.time) {
        style.display = 'none';  // 隐藏整个标签
      }
      if (item.isOptional) {
        style.backgroundColor = "#ffffff"
      }
      return style;
    },
    handleDateClick(item) {
      // console.log("handleDateClick: ",item)
      // console.log("handleDateClick.data: ",item.date)
      this.getTime(item.date)
    },
    selectTime(item) {
      console.log("selectTime: ", item)
      if (!item.isOptional) {
        this.text = "该时间忙，请选择其他时间"
        this.snackbar = true
        return
      }
      this.selectServiceTime(item.time)

    },

    closeDialog() {
      this.$emit('closeDialog')
    },
    selectServiceTime(time) {
      this.$emit('selectServiceTime', time);
      this.closeDialog();
    }
  },
}

</script>

<style scoped>
.select-time {
  padding: 22px 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 85px);
  place-items: center;
  grid-gap: 15px 5px;
}

.item-time {
  position: relative;
  padding: 14px 18px;
  background-color: gray;
}

.item-time .status-isOptional {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #cccccc;
  color: #ffffff;
  padding: 1px;
  font-size: 10px;
  line-height: 10px;
}

.item-time .status-isNight {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #d5ab54;
  color: #000000;
  padding: 1px;
  font-size: 10px;
  line-height: 10px;
}
</style>
