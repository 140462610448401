import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import {createPinia, PiniaVuePlugin} from "pinia";
import VueCountryIntl from 'vue-country-intl';
import 'vue-country-intl/lib/vue-country-intl.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {router} from "@/config/router";
import en from '@/locale/en';
import zh from "@/locale/zh";            // 导入English语言


Vue.use(Vuetify)

Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.use(vueMiniPlayer)

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAaMrKJM7_6h-KgS37hoNcjR_0Uk5qluU0', // 此处填入谷歌地图申请的key
        libraries: 'places'
    }
})
Vue.use(VueRouter);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
window.pinia = pinia

new Vue({
    router,
    vuetify: new Vuetify({
        lang: {
            locales: {zh, en},   // 所有的语言
            current: localStorage.getItem('userLocale') || 'zh',       // 当前语言
        }
    }),
    pinia,
    render: h => h(App)
}).$mount('#app');

