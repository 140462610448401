// src/locale/zh.js
import zhHans from 'vuetify/es5/locale/zh-Hans';  // 导入Vuetify 自带的简体中文语言包

export default {
    label: '简体中文',
    book: '预约',
    orders: '订单',
    me: '我的',
    ok: '好的',
    cancel: '取消',
    // my:{
    //     login: '登录',
    //     register: '注册',
    //     exit: '退出登录',
    //     switchLanguage: '切换语言',
    //     customerService: '在线客服'
    // },
    switchLanguage: '切换语言',
    english: '英文',
    chinese: '中文',
    changeToEnglish: '切换到英文',
    changeToChinese: '切换到中文',
    changeToSystem: '切换到系统语言',
    //我的页面
    commonVip: '普通会员',
    FollowTechnician: '关注技师',
    FollowServices: '关注服务',
    FollowStore: '关注店铺',
    paid: '待支付',
    served: ' 待服务',
    accepted: '待验收',
    evaluated: '待评价',
    MyOrder: '我的订单',
    friends: '邀请好友',
    Contactservice: '在线客服',
    Aftersales: '反馈',
    Myevaluation: '我的评价',
    Merchant: '商家管理',
    TechnicianOrders: '技师招募',
    BrowseHistory: '浏览历史',
    ViewAll: '查看全部',
    //直约列表
    TechnicianList: '直约技师',
    all: '全部',
    doorToDoorFast: '上门快',
    HighPopularity: '人气高',
    praise: '好评多',
    filter: '筛选',
    Comment: '评价',
    strip: '条',
    NoStaffNearby: '附近暂无工作人员',
    //我的订单
    orderNumber: '订单编号',
    totalOrder: '在线支付',
    orderTime: '下单时间',
    //技师详情
    Verified: '实名认证',
    age: '年龄',
    focusOn: '关注',
    Followed: '已关注',
    Zodiac: '生肖',
    constellation: '星座',
    nationality: '民族',
    education: '学历',
    height: '身高',
    weight: '体重',
    Introduction: '简介',
    businessLicense: '营业执照',
    healthCertificate: '健康证',
    masseurCertificate: '按摩师证',
    Check: '查看',
    serviceItems: '服务的项目',
    goodEvaluate: '好评',
    commentAmount: '评价数量',
    minute: '分钟',
    sold: '已售',
    SecondRate: '次',
    ReservationNow: '立即预约',
    doorToService: '上门服务',
    photoAlbum: '相册',
    video: '视频',
    //下单
    PlaceAnOrder: '下单',
    serviceAddress: '服务地址',
    hotel: '酒店/公寓',
    houseNumber: '房间/门牌号',
    Name: '姓名',
    Telephone: '电话',
    serviceHours: '服务时间',
    servicePersonnel: '服务人员',
    PromoCode: '优惠码',
    Inquire: '查询',
    paymentMethod: '支付方式',
    DepositPaid: '待支付定金',
    bookAndPay: '预约并支付',
    hotelPlaceholder: '请输入您所在的酒店或公寓名称',
    houseNumberPlaceholder: '详细地址，例如，A座101室',
    pinyinSpelling: '拼音全拼',
    gentlemen: '先生',
    Miss: '女士',
    PleaseSelectTime: '请选择服务时间（当地时间）',
    canComeEarly: '可提前来',
    callBeforeComing: '来之前打电话',
    PleaseContactMeAsSoonAsPossible: '请尽快联系我',
    businessMessage: '给商家捎句话',
    PleaseEnterDiscountCode: '请输入优惠码（选填）',
    Alipay: '支付宝支付',
    PayPal: 'MasterCard VISA等信用卡/PayPal',
    paymentSuccessful: '支付成功',
    paymentFailed: '支付失败',
    //我的关注/评价
    myFocus: '我的关注',
    myComment: '我的评价',
    technician: '技师',
    Serve: '服务',
    shop: '店铺',
    delete: '删除',
    Revise: '修改',
    details: '详情',
    //多客服
    //邀请注册
    inviteRegister: '邀请注册',
    invitationPassword: '邀请口令',
    copy: '复制',
    invitationSteps: '邀请步骤',
    ShareInvitationPostersOrInvitationLinksToFriends: '分享邀请海报或者邀请链接给好友',
    FriendsDownloadTheAPPThroughPostersOrLinks: '好友通过海报或者链接下载APP',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: '好友注册APP时填写您的邀请口令',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: '好友下载APP并使用您的邀请口令注册后，Ta将成为您的粉丝',
    saveThePoster: '保存专属海报到相册',
    //用户反馈
    customerFeedback: '用户反馈',
    selectQuestionType: '请选择反馈问题类型',
    massageFeedback: '上门按摩服务反馈',
    APPFeedback: 'APP反馈',
    otherFeedback: '其他问题反馈',
    YourQuestion: '您的问题（必填）',
    ContactInformation: '联系方式',
    submit: '提交',
    //设置
    setUp: '设置',
    personalInformation: '个人资料',
    changePassword: '修改密码',
    UserAgreement: '用户协议',
    PrivacyPolicy: '隐私政策',
    cancelAccount: '注销账户',
    modifyLanguage: '修改语言',
    signOut: '退出登录',
    logInImmediately: '立即登录',
    multiLanguage: '多语言',
    LanguageSettingSuggestions: '语言设置完成，建议您重新进入App',
    hint: '提示',
    OK: '好',
    //服务时间（当地时间）
    localTime: '（当地时间）',
    today: '今天',
    tomorrow: '明天',
    Monday: '周一',
    Tuesday: '周二',
    Wednesday: '周三',
    Thursday: '周四',
    friday: '周五',
    Saturday: '周六',
    sunday: '周日',
    night: '夜间',
    expired: '约满',
    confirmChoice: '确定选择',
    //我的足迹
    myFootprint: '我的足迹',
    //选择城市/地址
    SelectCity: '选择城市',
    selectAddress: '选择地址',
    nearbyCities: '附近城市',
    SearchCommunityNameOrBuildingName: '搜索小区名/大厦名',
    searchCityName: '搜索城市名',
    popular: '热门',
    CurrentCity: '当前城市',
    //筛选弹窗
    workingStatus: '工作状态',
    AvailableByAppointment: '可预约',
    TechnicianSex: '技师性别',
    unlimited: '不限',
    maleTechnician: '男技师',
    femaleTechnician: '女技师',
    technicianAge: '技师年龄',
    minimumAge: '最低年龄',
    maximumAge: '最高年龄',
    technicianName: '技师姓名',
    PleaseEnterTheTechnicianName: '请输入技师姓名',
    reset: '重置',
    Sure: '确定',
    //登录/注册
    Login: '登录',
    register: '注册',
    PleaseEnterThePhoneNumber: '请输入手机号码',
    pleaseEnterPassword: '请输入密码',
    SignInToAgree: '登录即代表同意',
    and: '和',
    VerificationCodeLogin: '验证码登录',
    NewUserRegistration: '新用户注册',
    pleaseEnterVerificationCode: '请输入验证码',
    getVerificationCode: '获取验证码',
    passwordLogin: '密码登录',
    Reacquire: '重新获取',
    PleaseSetAPasswordOfMoreThanSixCharacters: '请设置6位以上密码',
    pleaseEnterPasswordAgain: '请再次输入密码',
    InvitationCodeIsOptional: '邀请码非必填',
    InvitationCodeRequired: '邀请码,必填',
    AlreadyHaveAnAccountToLogin: '已有账号登录',
    //底部数据提示
    noMoreData: '没有更多数据了',
    loadingMoreData: '正在加载更多数据',
    //订单详情
    orderDetails: '订单详情',
    Finish: '完成',
    PendingPayment: '待付款',
    tobeDelivered: '待发货',
    TobeReceived: '待收货',
    WeChatPayment: '微信支付',
    toPay: '去支付',
    DeliveryMethod: '配送方式',
    TheAmountOfGoods: '项目应付金额',
    MembershipVoucherDeduction: '会员券抵扣',
    promoAmountTitle: '已优惠',
    deliveryFee: '配送费',
    payAmountTitle: '原价金额',
    reserveAmountTitle: '已支付定金',
    reserveAmountDescTitle: '定金说明',
    OrderList: '订单列表',
    Receipt: '确认收货',
    AccountBalanceDeduction: '账户余额抵扣',
    //登录/注册弹窗提示
    ToastPleaseEnterThePhoneNumber: '请输入手机号码',
    ToastValidNumber: '请输入有效的手机号码',
    ToastPleaseEnterPassword: '请输入密码',
    ToastAuthorizedLogin: '请您同意用户和隐私协议后登录',
    ToastLoginFailed: '登录失败',
    ToastSendCode: '验证码已发送',
    ToastCodeFailed: '验证码获取失败',
    ToastPleaseEnterVerificationCode: '请输入验证码',
    ToastLoading: '加载中...',
    ToastPasswordsDoNotMatch: '密码不一致',
    ToastPleaseEnterTheInvitationCode: '请输入邀请码',
    ToastRegistrationFailed: '注册失败',
    ToastCopySuccessfully: '复制成功',
    //暂无数据背景提示
    noOrderYet: '还没有订单哦',
    SelectService: '快去挑选心仪的服务吧',
    LoginToViewYourOrder: '登录后可查看您的订单',
    //选择国家弹窗
    Chinese: '中国大陆',
    HongKong: '中国香港',
    Thailand: '泰国',
    MacaoChina: '中国澳门',
    TaiwanChina: '中国台湾',
    USA: '美国USA',
    OtherCountries: '其他国家',
    ChooseACountry: '选择国家',
    orderCount: '已服务订单',
    Partner: '合作伙伴',
    testFirebase: 'testFirebase',
    malaysia: '马来西亚',
    singapore: '新加坡',
    ThaiBank: '泰国银行卡(提交后和客服要付款码)',
    OrderTaxiFee: '车费',
    OrderCashTotal: '应付现金合计',
    ETA: '预估到达时间',
    PlsSelectTime: '请选择时间',
    Success: '成功',
    workerName: '技师',
    otherPayment: '其他支付方式(提交后联系客服)',
    otherPaymentInfo: '支持Touch N Go等',
    AppStoreReview: 'AppStore打分',
    bwh: '三围',
    serviceReservationTime: '预约时间',
    noNeedKeyCard: '电梯不需要刷卡',
    goToRoomDirectly: '前台帮忙到房间',
    waitingAtLobby: '大厅等候',
    waitingAtLift: '电梯口等候',
    gotIt: '我知道了',
    depositDesc: '支付定金说明',
    bookingIndexTitle: '预约上门按摩到酒店 曼谷 芭提雅 清迈 普吉岛',

    ...zhHans,
}
